import React, { useEffect, useState } from "react"
import loadable from "@loadable/component"
import Modal from "react-modal"
import Seo from "../components/seo"
import Layout from "../components/layout/layout"
import { LocaleProvider } from "../hooks/useLocale"
import useTranslation from "../hooks/useTranslation"
import { useLocation } from "@reach/router"
import { useLocalStorage } from "../hooks/useLocalStorage"

const Hero2 = loadable(() => import("../components/hero/Hero2"))
const Services = loadable(() => import("../components/services/Services"))
const Company = loadable(() => import("../components/company/Company"))
const Tech = loadable(() => import("../components/tech/Tech"))
const Models = loadable(() => import("../components/models/Models"))
const ContactForm = loadable(() => import("../components/form/ContactForm"))
const Footer = loadable(() => import("../components/footer/Footer"))
const Values = loadable(() => import("../components/values/Values"))
const Process = loadable(() => import("../components/process/Process"))

const App = ({ pageContext: { locale } }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const { pathname } = useLocation()
    const urlLang = pathname.split("/")[1]

    const translations = useTranslation()
    const [, setLangToStorage] = useLocalStorage()

    useEffect(() => {
        setLangToStorage(urlLang)
    }, [])
    const customStyles = {
        content: {},
        overlay: {
            backgroundColor: "rgba(0,0,0,0.7)",
        },
    }
    Modal.setAppElement("body")
    return (
        <>
            <Layout
                translations={translations}
                locale={locale}
                openModal={() => setModalOpen(true)}
            >
                <Seo locale={locale} translations={translations} title="Enterosoft" />
                <Hero2 translations={translations} />
                <Services translations={translations} />
                <Company translations={translations} />
                <Values translations={translations} />
                <Process translations={translations} />
                <Models translations={translations} />
                <Tech translations={translations} />
                <Footer
                    translations={translations}
                    locale={locale}
                    openModal={() => setModalOpen(true)}
                />
            </Layout>
            <Modal className="contact__modal" style={customStyles} isOpen={modalOpen}>
                <ContactForm
                    translations={translations}
                    locale={locale}
                    closeModal={() => setModalOpen(false)}
                />
            </Modal>
        </>
    )
}

const IndexPage = ({ children, pageContext }) => (
    <LocaleProvider>
        <App pageContext={pageContext}>{children}</App>
    </LocaleProvider>
)
export default IndexPage
